/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-28 11:10:10
 * @LastEditTime: 2023-04-14 15:08:27
 * @LastEditors: dongqianqian
 * @Usage: 
 */
import Axios from "axios";
import { Toast } from "vant";
import errorCode from "@/utils/errorCode";
import { getUrlParams2 } from '@/utils/utils'

Axios.defaults.withCredentials = true;
Axios.withCredentials = true;

const service = Axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
});
// 请求拦截
service.interceptors.request.use(
  (config) => {
    // 设置url
    let { url } = config;
    const urlEnv = getUrlParams2().env || window.getStore({ name: 'env' });
    if (process.env.NODE_ENV == "production") {
      let spliceStr =
        url.split("/")[1] && url.split("/")[1] == "api"
          ? (url.indexOf('js-ticket') > -1 ? (urlEnv == 'Test114' ? `/${urlEnv}` : '/Prod') : `/${urlEnv}`)
          : "/center";
      if (url.indexOf('/geocoder') > -1) {
        config.url = '' + config.url;
      } else {
        config.url = spliceStr + config.url;
      }
    }
    // 添加时间戳
    let times = new Date().getTime();
    config.params = config.params ? { ...config.params, times } : { times };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (res) => {
    let {
      data: { statusCode },
      status,
    } = res;
    let message =
      res.data.errors ||
      res.data.msg ||
      errorCode[status] ||
      errorCode["default"];

    if (status !== 200 || statusCode !== 200) {
      // 登录失效走 jky-utils 中的退出登录方法
      if ([401, 403].indexOf(statusCode) !== -1) return Promise.resolve(res);
      Toast.fail({ message, duration: 8000 });
    }
    return Promise.resolve(res.data);
  },
  (error) => {
    return Promise.resolve(error);
  }
);

export default service;
