/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-24 11:23:09
 * @LastEditTime: 2023-03-27 18:23:23
 * @LastEditors: dongqianqian
 * @Usage: 
 */
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/layout"),
    redirect: "/clock",
    children: [
      {
        path: "/night",
        name: "night",
        component: () => import("@/views/nightConstruction/index"),
        meta: {
          title: "劳务打卡",
        },
      },
      {
        path: "/clock",
        name: "clock",
        component: () => import("@/views/clockIn/index"),
        meta: {
          title: "劳务打卡",
        },
      },
      // {
      //   path: "/clock",
      //   name: "clock",
      //   component: () => import("@/views/clockIn/index"),
      //   meta: {
      //     title: "劳务打卡",
      //   },
      // },
    ],
  },
];

export default routes;
