/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-28 11:10:10
 * @LastEditTime: 2023-04-06 14:53:38
 * @LastEditors: dongqianqian
 * @Usage: 
 */
import request from '@/config/http';

/**
 * 判断是否为空
 */
export const validatenull = (val) => {
  if (typeof val === 'boolean') {
    return false;
  }
  if (typeof val === 'number') {
    return false;
  }
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (
      val === 'null' ||
      val == null ||
      val === 'undefined' ||
      val === undefined ||
      val === ''
    )
      return true;
    return false;
  }
  return false;
};

// 获取URL参数
export const getUrlParams = () => {
  const url = decodeURI(window.location.href)
  const params = {}
  if (!url || url === '' || typeof url !== 'string') {
    return params
  }
  const paramsStr = url.split('?')[1]
  if (!paramsStr) {
    return params
  }
  const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
  for (let i = 0; i < paramsArr.length / 2; i++) {
    const value = paramsArr[i * 2 + 1]
    params[paramsArr[i * 2]] =
      value === 'true' ? true : value === 'false' ? false : value
  }
  return params
};


// 获取URL参数
export const getUrlParams2 = () => {
  var url = window.location.search; //获取url中"?"符后的字串 ('?modFlag=business&role=1')
  var theRequest = new Object();
  if (url.indexOf('?') !== -1) {
    var str = url.slice(1); //substr()方法返回从参数值开始到结束的字符串；
    var strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
    }
  }
  return theRequest;
};

// 保存文件
export const downloadFileUrl = async (file, fileName) => {
  let blob = '';
  if (file instanceof Blob) {
    blob = file;
  } else {
    let { data } = await request({
      url: file,
      method: 'get',
      responseType: 'blob',
      data: { downFile: 'down' },
    });
    blob = data;
  }
  const elink = document.createElement('a');
  elink.href = URL.createObjectURL(blob);
  elink.download = fileName || '下载文件';
  elink.style.display = 'none';
  document.body.appendChild(elink);
  elink.click();
  setTimeout(() => {
    URL.revokeObjectURL(blob);
    document.body.removeChild(elink);
  }, 300);
};
