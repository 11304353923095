/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-24 11:23:09
 * @LastEditTime: 2023-03-30 23:14:29
 * @LastEditors: dongqianqian
 * @Usage: 
 */
import Vue from "vue";
import App from "./App.vue";

// rem
import "lib-flexible";

// 路由
import router from "./router";

// api
import api from "./api";
Vue.prototype.$api = api;


// vant ui
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// vconsole
import VConsole from "vconsole";
if (process.env.NODE_ENV === "development") {
  let vconsole = new VConsole();
  Vue.use(vconsole);
}


import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key
  ak: 'UDuLsRhmJxeEIv8KJ9AppkHg1EWVWtU9'
})

import "./utils/storage";
import "./config/config";

// baseurl 图片域名
import { BASE_URL } from "./utils/constant";
Vue.prototype.$baseUrl = BASE_URL;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
