/*
 * @Description: 
 * @Author: 
 * @Date: 2023-04-12 15:59:03
 * @LastEditTime: 2023-04-14 14:17:54
 * @LastEditors: dongqianqian
 * @Usage: 
 */
import http from "@/config/http";

export default {
  // 保存检查项
  getWx(data) {
    return http({
      url: "/api/labor-m-ver2-module/js-ticket",
      method: "post",
      data,
    });
  },
  // 检查考勤
  getWx1(data) {
    return http({
      url: "/api/labor-m-ver2-module/check-and-obtain-position-key",
      method: "post",
      data,
    });
  },
  // 通过SK获取项目名称和服务器时间
  getProAndTime(data) {
    return http({
      url: `/api/labor-m-ver2-module/project-and-server-time-by-sk`,
      method: "get",
      params: { sk: data.sk }
    });
  },
  // 通过身份证获取个人信息和今日考勤信息
  getInfo(data) {
    return http({
      url: "/api/labor-m-ver2-module/person-info-by-id-card-with-today-attendance",
      method: "post",
      data,
    });
  },
  // 检查考勤位置并获取PositionKey
  getPositionKey(data) {
    return http({
      url: "/api/labor-m-ver2-module/check-and-obtain-position-key",
      method: "post",
      data,
    });
  },
  // 点击打卡
  clockIn(data) {
    return http({
      url: "/api/labor-m-ver2-module/clock-in",
      method: "post",
      data,
    });
  },
};