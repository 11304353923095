import http from "@/config/http";

export default {
  // 图片上传
  uploadImage(data) {
    return http({
      url: "/api/night-construction-module/upload-file",
      method: "post",
      headers: {
        "content-type": "multipart/form-data",
      },
      data,
    });
  },
};
