/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-24 11:23:09
 * @LastEditTime: 2023-03-27 17:09:30
 * @LastEditors: dongqianqian
 * @Usage: 
 */
import http from "@/config/http";

export default {
  // 检查项列表
  checkList() {
    return http({
      url: "/api/night-construction-module/night-construction-item-list",
    });
  },
  // 保存检查项
  saveCheckResult(data) {
    return http({
      url: "/api/night-construction-module/save-night-construction-record",
      method: "post",
      data,
    });
  },
};
