/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-28 11:10:10
 * @LastEditTime: 2023-03-30 23:34:13
 * @LastEditors: dongqianqian
 * @Usage: 
 */
import { getUrlParams2 } from '@/utils/utils';

// 设置默认值
const userId = getUrlParams2().userId;
const companyId = getUrlParams2().companyId;
const projectId = getUrlParams2().projectId;
const projectName = getUrlParams2().projectName;
const sk = getUrlParams2().sk || window.getStore({ name: 'sk' });
const pid = getUrlParams2().pid || window.getStore({ name: 'pid' });
const env = getUrlParams2().env || window.getStore({ name: 'env' });
window.setStore({ name: 'ProjectID', content: projectId || '39' });
window.setStore({ name: 'companyID', content: companyId || '63' });
window.setStore({ name: 'userId', content: userId || '122' });
window.setStore({ name: 'projectName', content: projectName });
window.setStore({ name: 'sk', content: sk });
window.setStore({ name: 'pid', content: pid });
window.setStore({ name: 'env', content: env });
